<div id="footer">
  <ul class="FotLinks">
    <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tantalize</a></li>
    <li><a routerLink="/discover" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Discover</a></li>
    <li><a routerLink="/visuals" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">visuals</a></li>
    <li><a routerLink="/sessions" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">sessions</a></li>
    <li><a routerLink="/decorum" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">decorum</a></li>
    <li><a routerLink="/tours" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tours</a></li>
    <li><a routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">contact</a></li>

  </ul>
  <div id="copyright">
      2014 - {{year}} <a >Sashanoelle.nl</a> All Rights Reserved.
   </div>

</div>
 <!-- <ul id="SocialMedia"> -->
    <!-- <li><a href="https://twitter.com/TheSashaNoelle" target="_blank"><img src="/assets/images/social_twitter.svg" width="20" alt="Twitter" /></a></li>
    <li><a href="https://www.instagram.com/TheSashaNoelle/" target="_blank"><img src="/assets/images/social_instagram.svg" height="20" alt="Instagram" /></a></li>
    <li><a href="https://preferred411.com/P115938" target="_blank"><img src="/assets/images/social_p411.svg"  height="20" alt="P411" /></a></li>
    <li><a href="https://www.theeroticreview.com/reviews/show.asp?id=223656" target="_blank"><img src="/assets/images/social_ter.svg" width="20" alt="TER" /></a></li>
    <li><a href="https://privatedelights.ch/profile/SashaNoelle" target="_blank"><img  src="/assets/images/social_private_delights.svg" width="20" alt="PrivateDelights" /></a></li> -->
    <!-- <li><a href="https://switter.at/@SashaNoelle" target="_blank"><img src="/assets/images/social_switter.svg" height="20" alt="Switter" /></a></li> -->
  <!-- </ul> -->
